<script lang="ts">import Wrap from '$components/slot/Wrap.svelte';
</script>

<Wrap>
  <figure>
    <img src="/images/fudder.png" alt="フッター">
  </figure>
</Wrap>

<footer>
  <Wrap>
    <nav>
      <p>
        <a href="/privacy-policy">プライバシーポリシー</a>
      </p>

      <p>Kyowa Jidosha All rights reserved.</p>
    </nav>
  </Wrap>
</footer>

<style type="text/scss">img {
  display: block;
  width: 100%;
}

footer {
  margin-top: 0.5rem;
  padding-bottom: 3rem;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #727272;
}

a {
  display: block;
  padding: 0 1rem;
  color: #727272;
  text-decoration: none;
  line-height: 1.5;
  border-left: 1px solid #727272;
  border-right: 1px solid #727272;
}
a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  a {
    padding: 0 0.5rem;
  }

  p {
    font-size: 0.95rem;
  }
}</style>
