<script lang="ts">export let fullOnMobile = false;
</script>

<div class:full-on-mobile={fullOnMobile}>
  <slot></slot>
</div>

<style type="text/scss">div {
  position: relative;
  margin: 0 auto;
  width: 1240px;
}

@media only screen and (max-width: 1442px) {
  div {
    width: 1030px;
  }
}
@media only screen and (max-width: 1069px) {
  div {
    width: 760px;
  }
}
@media only screen and (max-width: 768px) {
  div {
    width: 470px;
  }

  .full-on-mobile {
    width: 100%;
  }
}
@media only screen and (max-width: 481px) {
  div {
    width: 94%;
  }

  .full-on-mobile {
    width: 100%;
  }
}</style>
