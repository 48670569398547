<script lang="ts">import Wrap from '$components/slot/Wrap.svelte';
import Sitelogo from '$components/element/Sitelogo.svelte';
</script>

<header>
  <Wrap>
    <div class="inner">
      <h1 class="sitelogo">
        <Sitelogo></Sitelogo>
      </h1>
    </div>
  </Wrap>
</header>

<style type="text/scss">header {
  padding: 1.5rem 0;
}</style>
