<script lang="ts"></script>

<a href="/">
  <img src="/images/logo.png" alt="協和自動車有限会社">
</a>

<style type="text/scss">a {
  display: block;
  text-decoration: none;
}

img {
  display: block;
  width: 275px;
}</style>
